<template>
  <section
    v-if="formData.systemSettings"
    class="invoice-preview-wrapper"
  >
    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        id="printMe"
        ref="printMe"
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column mt-0">

              <!-- Header: Left Content -->
              <div class="d-flex">
                <div class="logo-wrapper">
                  <b-img
                    :src="require('@/assets/images/logo/tm-partial-logo-2.png')"
                    alt="logo"
                  />
                  <h3 class="text-primary invoice-logo ml-0">
                    {{ appName }}
                  </h3>
                </div>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  {{ formData.invoiceNo }}
                </h4>
                <div class="invoice-date-wrapper mb-0">
                  <p class="invoice-date-title">
                    Date Issued:
                  </p>
                  <p class="invoice-date">
                    {{ formData.dateIssued }}
                  </p>
                </div>
                <div
                  v-if="formData.dueDate"
                  class="invoice-date-wrapper"
                >
                  <p class="invoice-date-title">
                    Due Date:
                  </p>
                  <p class="invoice-date">
                    {{ formData.dueDate }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">
          <b-card-body
            v-if="formData.invoice_status === 'paid' && payment.paidWith === 'btc'"
            class="invoice-padding pt-0"
          >
            <div>
              <center><h5><u>THIS IS JUST A PAYMENT NOTIFICATION - NOT AN INVOICE</u></h5></center>
            </div>
          </b-card-body>

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <div class="invoice-spacing d-flex justify-content-between">
              <!-- Col: Invoice From -->
              <div
                class="p-0"
              >
                <h6 class="mb-2">
                  From:
                </h6>
                <h6 class="mb-25 font-weight-bolder">
                  {{ formData.systemSettings.company_name }}
                </h6>
                <p class="card-text mb-25">
                  {{ formData.systemSettings.company_address1 }}
                </p>
                <!-- <p class="card-text mb-25">
                  {{ formData.systemSettings.company_address2 }}
                </p> -->
                <p class="card-text mb-25">
                  {{ formData.systemSettings.vatno }}
                </p>
              </div>

              <!-- Col: To -->
              <div
                v-if="isAnulat===0"
                class="p-0"
              >
                <h6 class="mb-2">
                  To:
                </h6>
                <h6 class="mb-25 font-weight-bolder">
                  {{ formData.client }}
                </h6>
                <p class="card-text mb-25 invoice-to-address">
                  {{ formData.address + ', ' + formData.country }}
                </p>
                <p
                  v-if="formData.tax"
                  class="card-text mb-25"
                >
                  Tax-ID/VAT: {{ formData.tax }}
                </p>
              </div>
            </div>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="formData.invoiceData.items"
            :fields="['itemTitle', 'qty', 'unitPrice', 'itemPrice']"
          >

            <template #head(itemTitle)>
              <span>Item</span>
            </template>

            <template #head(unitPrice)>
              <span>Unit Price</span>
            </template>

            <template #head(itemPrice)>
              <span>Item Price</span>
            </template>

            <template #cell(itemTitle)="data">
              <b-card-text class="font-weight-bold">
                {{ data.value.text ? data.value.text : data.value }}
                <br><small>{{ smallTextInfo(data.value) }}</small>
              </b-card-text>
            </template>
          </b-table-lite>

          <p
            v-if="formData.periodDate.startDate"
            class="w-100 invoice-period"
          >
            Period: {{ formData.periodDate.startDate + ' - ' + formData.periodDate.endDate }}
          </p>

          <b-card-body
            v-if="formData.invoice_status === 'paid' && formData.paid_by_balance > 0"
            class="invoice-padding pt-0"
          >
            <div
              class="col-12 d-flex justify-content-center pb-2 font-weight-bolder "
              style="font-size: 100%; background: -webkit-linear-gradient(red, blue);
                -webkit-background-clip: text;
                background-clip: text;
                -webkit-text-fill-color: transparent;"
            >
              <center><h5><u>AMOUNT DEDUCTED FROM BALANCE</u></h5></center>
            </div>
          </b-card-body>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Total -->
              <b-col
                cols="12"
                class="mt-md-6 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount">
                      {{ formData.currency + ' ' + roundHalfUp(parseFloat(formData.subTotal)).toFixed(2) }}
                    </p>
                  </div>
                  <div
                    v-if="formData.discountValue > 0"
                    class="invoice-total-item"
                  >
                    <p class="invoice-total-title">
                      Discount:
                    </p>
                    <p class="invoice-total-amount">
                      - {{ formData.currency + ' ' + roundHalfUp(parseFloat(formData.discountValue)).toFixed(2) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      VAT ({{ formData.vatPercentage }}%):
                    </p>
                    <p class="invoice-total-amount">
                      {{ formData.currency + ' ' + roundHalfUp(formData.vat).toFixed(2) }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      {{ formData.currency + ' ' + roundHalfUp(parseFloat(formData.totalToPay)).toFixed(2) }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <span v-if="invoiceType === 'invoice' && invoiceDetails.exchange_rate">
            <p
              v-if="invoiceDetails.version === 2 && invoiceDetails.currency !== 'RON'"
              class="text-right mt-1 mr-2 pr-1 mb-0"
            >
              Total in Lei (exchange rate BNR: {{ invoiceDetails.exchange_rate }} ): {{ parseFloat(invoiceDetails.exchange_rate * invoiceDetails.invoice_value).toFixed(2) }} RON
            </p>
          </span>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body
            v-if="formData.vatPercentage === 0 && isCreditNote === 0"
            class="invoice-padding text-center pt-0"
          >
            <p v-if="invoiceDetails.version === 1">
              Exempt by virtue of Art 7 of the Twelfth Schedule to the Value Added Tax Act - Chapter 406
            </p>
            <p v-else>
              Customer to account for any VAT arising on this supply in accordance with Article 196 Council Directive 2016/112/EC
            </p>
            <p
              v-if="payment && formData.invoice_status.toLowerCase().trim()==='paid'"
              class="paid-style mt-1"
            >
              <span v-if="payment.paidWith === 'creditCard'">
                Paid by Credit Card on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.payment + 'I' + payment.invoice }}
              </span>
              <span v-else-if="payment.paidWith === 'credit_card'">
                Paid by Credit Card on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.ipc_trnref ? payment.ipc_trnref : invoiceDetails.invoice_number_v2 }}
              </span>
              <span v-else-if="payment.paidWith === 'payPal'">
                Paid by PayPal on {{ payment.payment_date }}<br>Transaction Reference: {{ payment.ipc_trnref }}
              </span>
              <span v-else-if="payment.paidWith === 'wire'">
                Paid by Wire Transfer on {{ payment.payment_date }}
              </span>
              <span v-else-if="payment.paidWith === 'crypto'">
                Paid by Crypto on {{ payment.payment_date }}
              </span>
            </p>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Invoice Status -->
          <div
            class="mt-75 paid-style"
            block
          >
            <p
              v-if="formData.invoice_status==='pending'"
              class="text-warning"
            >
              <b>Invoice Status: {{ formData.invoice_status }} payment</b>
            </p>
            <p
              v-else-if="formData.invoice_status==='overdue'"
              class="text-danger"
            >
              <b>Invoice Status: {{ formData.invoice_status }}</b>
            </p>
            <p
              v-else
              class="text-capitalize"
            >
              <b>Invoice Status: {{ formData.invoice_status == 6 ? 'Referral balance applied' : formData.invoice_status }}  {{ payment.paidWith ? getMethod(payment.paidWith) : '' }}</b>
            </p>
          </div>
          <br>

          <!-- Button: Edit -->
          <b-button
            v-if="formData.invoice_status==='pending' || formData.invoice_status==='overdue'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="info"
            class="mb-75"
            block
            :disabled="isLoading2"
            @click="mark_as_paid"
          >
            <span v-if="isLoading2">
              <b-spinner
                small
                type="grow"
              />
              Marking as paid...
            </span>
            <span v-else>Mark as Paid</span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
            :disabled="isLoading2"
            @click="edit"
          >
            Edit
          </b-button>

          <!-- Button: Download -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            block
            :disabled="downloading"
            @click="download"
          >
            <span v-if="downloading">
              <b-spinner
                small
                type="grow"
              />
              Downloading...
            </span>
            <span v-else>Download</span>
          </b-button>

          <!-- Button: View User -->
          <b-button
            v-if="isAnulat===0"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            block
            :disabled="isLoading2 || downloading"
            @click="viewUser"
          >
            View User
          </b-button>

          <!-- Button: View Efactura -->
          <b-button
            v-if="efactura.length > 0 && user.isAdmin"
            v-b-modal.efactura-logs-modal
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-info"
            block
          >
            View Efactura Logs
          </b-button>

        </b-card>
      </b-col>

      <!-- Show Efactura Logs -->
      <b-modal
        v-if="efactura.length > 0"
        id="efactura-logs-modal"
        ok-title="Close"
        ok-only
        centered
        size="lg"
        title="Efactura Logs"
      >
        <b-row>
          <b-col>
            <b-row
              v-for="(log, index) in efactura"
              :key="index"
            >
              <b-col cols="12">
                <b-card-text>
                  <b class="text-primary">Date:</b> {{ log.date }}<br>
                  <b
                    v-if="log.extra_data['xml']"
                    class="text-warning"
                  >XML: <span
                    class="text-success"
                    style="cursor: pointer;"
                    @click="downloadXML(log.extra_data['xml'])"
                  >Download XML</span><br></b>
                  <div
                    v-for="data in Object.keys(log.extra_data)"
                    :key="data"
                  >
                    <span v-if="data != 'xml'">
                      <b class="text-warning">{{ data }}:</b> {{ log.extra_data[data] }}<br>
                    </span>
                  </div><br>
                </b-card-text>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BTableLite, BButton, BImg, BCardText, VBToggle, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { $themeConfig } from '@themeConfig'
import axios from '@/libs/axios'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BButton,
    BImg,
    BCardText,
    BSpinner,
  },
  data() {
    return {
      uid: 0,
      status: 'Paid',
      invoiceType: '',
      invoiceDetails: {},
      payment: null,
      items: {},
      appName: null,
      pdfFilename: '',
      isLoading2: false,
      formData: {
        dateIssued: '',
        dueDate: '',
        invoiceTo: null,
        client: '',
        address: '',
        tax: '',
        country: '',
        subTotal: 0,
        totalToPay: 0,
        vat: 0,
        vatPercentage: 0,
        bankDetails: '',
        existingClients: false,
        systemSettings: null,
        invoiceNo: 0,
        currency: '',
        discountValue: 0,
        invoice_status: '',
        paid_by_balance: 0,
        periodDate: {
          startDate: null,
          endDate: null,
        },
        invoiceData: {
          items: [],
        },
      },
      downloading: false,
      isAnulat: 0,
      isCreditNote: 0,
      efactura: [],
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
  },
  created() {
    this.getInvoiceByID()
    this.appName = $themeConfig.app.appName
  },
  methods: {
    downloadXML(xml) {
      const link = document.createElement('a')
      const xml_url = URL.createObjectURL(new Blob([xml], { type: 'text/xml' }))
      link.setAttribute('href', xml_url)
      link.setAttribute('download', 'xml.xml')
      link.style.visibility = 'hidden'
      link.id = 'downloadXML'
      document.body.appendChild(link)
      document.getElementById(link.id).click()

      // remove to make sure a new csv file will be downloaded and not the previous one
      const element = document.getElementById(link.id)
      element.remove()
    },
    roundHalfUp(num) {
      return Math.ceil(num * 100) / 100
    },
    smallTextInfo(value) {
      if (value?.text === 'Late Payment Penalty Compensation' || value === 'Late Payment Penalty Compensation') return 'in line with the European Late Payment Directive (2011/7/EU)'
      return ''
    },
    mark_as_paid() {
      this.$swal({
        text: 'Do you want to mark this invoice as paid?',
        icon: 'question',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary mr-1',
          cancelButton: 'btn btn-secondary',
        },
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        buttonsStyling: false,
      }).then(res => {
        if (res.isConfirmed) {
          this.isLoading2 = true
          this.$store.dispatch('invoice/updateStatusInvoice', this.$route.params.id)
            .then(res2 => {
              if (res2) {
                this.notify({
                  text: 'Invoice updated!',
                  variant: 'success',
                })
                this.isLoading2 = false
                this.getInvoiceByID()
              }
            })
        }
      })
    },
    getMethod(me) {
      if (me === 'creditCard') return ' - Credit Card'
      if (me === 'credit_card') return ' - Credit Card'
      if (me === 'payPal') return ' - PayPal'
      if (me === 'wire') return ' - Wire Transfer'
      if (me === 'Referral ballance applied.') return ' - Referral balance applied'
      if (me === 'btc') return ' - BTC Payment'
      if (me === 'crypto') return ' - Crypto Payment'
      if (me === 'Paid - Admin') return ' - Admin'
      if (me === null) return ''
      return ''
    },
    getInvoiceByID() {
      this.$store.dispatch('invoice/getInvoiceByID', this.$route.params.id)
        .then(res => {
          this.isAnulat = Number(res.is_anulat)
          this.invoiceType = res.invoiceType
          this.invoiceDetails = res.invoice
          this.formData.systemSettings = res.systemSettings
          this.uid = res.invoice.uid
          this.formData.paid_by_balance = res.invoice.paid_by_balance
          this.efactura = res.efactura.map(x => ({
            date: new Date(x.created_at),
            extra_data: x.extra_data,
          }))

          this.payment = res.payment
          if (!res.payment) this.payment = 'N/A'
          if (this.isAnulat) {
            this.payment = { paidWith: 'anulat' }
          }

          if (!(res.invoice.invoice || res.invoice.invoice_number_v2) || res.invoice.is_credit_note === 1) this.isCreditNote = 1
          if (this.isAnulat === 1) this.isCreditNote = 0

          if (res.invoiceType === 'invoice') {
            this.formData.invoice_status = res.invoice.invoice_status

            if (this.isCreditNote === 0 && res.invoice.paid_by_balance === 0) {
              this.formData.invoiceNo = 'Invoice #'
              if (res.invoice.version === 1) this.formData.invoiceNo += res.invoice.invoice
              else this.formData.invoiceNo += `TM${res.invoice.invoice_number_v2.toString().padStart(4, '0')}`
              this.pdfFilename = this.formData.invoiceNo
            } else {
              this.formData.invoiceNo = `Pro Forma Invoice #${res.invoice.credit_note}`
              this.pdfFilename = `Pro Forma #${res.invoice.credit_note}`
            }

            this.formData.address = res.billing.bill_address1
            // if (res.billing.bill_address2) this.formData.address += `${res.billing.bill_address1}, ${res.billing.bill_address2}`
            this.formData.address += ` ${res.billing.bill_city}, ${res.billing.bill_postcode} ${res.billing.bill_region}`

            this.formData.dateIssued = res.invoice.issue_date
            this.formData.dueDate = res.invoice.due_date
            this.formData.client = res.billing.bill_name

            if (!this.formData.client) this.formData.client = res.invoice_to_name
            if (this.isAnulat) this.pdfFilename = `${this.pdfFilename} ANULAT`
            else this.pdfFilename = `${this.pdfFilename} ${this.formData.client}`

            this.formData.tax = res.billing.bill_vatno
            this.formData.country = res.billingCountry

            const cur = res.invoice.currency.toUpperCase()

            res.items.forEach(item => {
              let itemPrice = item.item_price
              let unitPrice = item.unit_price
              const { type } = item
              const mainItem = item.item

              if (cur === 'RON') {
                itemPrice = item.item_price * res.invoice.exchange_rate
                unitPrice = item.unit_price * res.invoice.exchange_rate
              }

              this.formData.invoiceData.items.push({
                itemTitle: item.item,
                qty: `${this.formatNumber(item.qty, 0)}`,
                unitPrice: `${res.invoice.currency} ${this.formatNumber(unitPrice, type, mainItem)}`,
                itemPrice: `${res.invoice.currency} ${this.formatNumber(itemPrice, type, mainItem)}`,
              })
            })

            this.formData.periodDate.startDate = res.invoice.start_date
            this.formData.periodDate.endDate = res.invoice.end_date
            this.formData.currency = res.invoice.currency
            this.formData.subTotal = parseFloat(res.invoice.subtotal_value).toFixed(2)
            this.formData.totalToPay = parseFloat(res.invoice.invoice_value).toFixed(2)
            this.formData.vat = res.invoice.vat_value
            this.formData.vatPercentage = res.invoice.vat
            this.formData.discountValue = res.invoice.discount_value
          } else {
            this.formData.invoice_status = res.invoice.invoice_status
            this.formData.invoiceNo = res.invoice.version === 1 ? `Invoice# ${res.invoice.invoiceNo}` : `Invoice#  TM${res.invoice.invoice_number_v2.toString().padStart(4, '0')}`

            this.pdfFilename = this.formData.invoiceNo

            this.formData.dateIssued = res.invoice.issueDate
            this.formData.dueDate = res.invoice.dueDate
            this.formData.client = res.invoice.to
            this.pdfFilename = `${this.pdfFilename} ${this.formData.client}`

            if (!this.formData.client) this.formData.client = res.invoice_to_name

            this.formData.address = res.invoice.userAddress
            this.formData.country = res.billingCountry
            this.formData.tax = res.invoice.userVAT
            this.formData.subTotal = parseFloat(res.invoice.subtotal).toFixed(2)
            this.formData.totalToPay = parseFloat(res.invoice.total).toFixed(2)
            this.formData.vat = parseFloat(res.invoice.vatValue).toFixed(2)
            this.formData.vatPercentage = res.invoice.vatPercent
            this.formData.periodDate.startDate = res.invoice.periodStart
            this.formData.periodDate.endDate = res.invoice.periodEnd
            this.formData.currency = res.invoice.currency

            let itemPriceFirst = res.invoice.packageItemPrice
            let unitPriceFirst = res.invoice.packageUnitPrice

            const cur = res.invoice.currency.toUpperCase()
            if (cur === 'RON') {
              itemPriceFirst = res.invoice.packageItemPrice * res.invoice.exchange_rate
              unitPriceFirst = res.invoice.packageUnitPrice * res.invoice.exchange_rate
            }

            this.formData.invoiceData.items.push({
              itemTitle: res.invoice.packageName,
              qty: res.invoice.packageQty,
              unitPrice: `${res.invoice.currency} ${parseFloat(unitPriceFirst).toFixed(2)}`,
              itemPrice: `${res.invoice.currency} ${parseFloat(itemPriceFirst).toFixed(2)}`,
            })

            res.items.forEach(item => {
              let itemPrice = item.item_price
              let unitPrice = item.unit_price
              const { type } = item
              const mainItem = item.item

              if (cur === 'RON') {
                itemPrice = item.item_price * res.invoice.exchange_rate
                unitPrice = item.unit_price * res.invoice.exchange_rate
              }

              this.formData.invoiceData.items.push({
                itemTitle: item.item,
                qty: `${this.formatNumber(item.qty, 0)}`,
                unitPrice: `${res.invoice.currency} ${this.formatNumber(unitPrice, type, mainItem)}`,
                itemPrice: `${res.invoice.currency} ${this.formatNumber(itemPrice, type, mainItem)}`,
              })
            })
          }
        })
    },
    async download() {
      this.downloading = true
      const url = `/api/invoices/download/${this.$route.params.id}`
      await axios.get(url, { responseType: 'blob' })
        .then(res => {
          const pdfFilename = this.removeDot(this.pdfFilename)
          const link = document.createElement('a')
          const pdf_url = URL.createObjectURL(res.data)
          link.setAttribute('href', pdf_url)
          link.setAttribute('download', pdfFilename)
          link.style.visibility = 'hidden'
          link.id = 'downloadPDF'
          document.body.appendChild(link)
          document.getElementById(link.id).click()

          // remove to make sure a new csv file will be downloaded and not the previous one
          const element = document.getElementById(link.id)
          element.remove()
        })
      this.downloading = false
    },
    removeDot(str) {
      const str_array = str.split('.')
      let filename = ''
      str_array.forEach(x => {
        filename += x
      })
      return filename
    },
    edit() {
      this.$router.push(`/invoices/${this.$route.params.id}/edit`)
    },
    viewUser() {
      this.$router.push(`/user/details/${this.uid}`)
    },
    formatNumber(price, type, title = null) {
      const priceFinal = Number(price)
      if ((title && title?.toLowerCase().includes('processing fee')) || type === 3) {
        return priceFinal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
      if (type === 5) {
        return priceFinal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 6,
        })
      }
      return priceFinal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 4,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.invoice-details-logo {
  width: 100%;
  max-width: 25rem;
}

.invoice-to-address {
  max-width: 20rem;
  line-height: 24px;
}

.invoice-period {
  padding: 10px 29px;
  border-top: 1px solid #e7e7e7;
  font-weight: 500;
  font-size: 14px;
  margin-top: -1rem;
}

.paid-style {
  color: #189550;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__dropdown-toggle {
  border: 1px solid #d8d6de !important;
}

@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
